form {
    width: 50%;
    position: relative;
    overflow: hidden;
    .field {
        padding: 10px 0;
        color: white;
        label {
            font-size: 18px;
        }
        p {
            margin: 0;
        }
        input, textarea {
            height: 36px;
            width: 100%;
            margin-top: 6px;
            border-radius: 3px;
            border: none;
            font-size: 16px;
            font-family: 'Hind Madurai', sans-serif;
        }
        textarea {
            height: 140px;
        }
    }
    .error {
        color: #ff7d7d;
    }
}

@media (max-width : 1000px) {
    form {
        width: 80%;
    }
}