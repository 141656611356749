.button {
    height: 40px;
    border: none;
    border-radius: 3px;
    font-size: initial;
    background: #ffffff5e;
    color: white;
    cursor: pointer;
    padding: 0 16px;
    display: flex;
    align-items: center;
    width: max-content;
    margin: 0;
    &:hover {
        background: #ffffffb0;
        color: #1f7461;
    }
}