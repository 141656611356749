@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:wght@700&family=Bubbler+One&family=Gabriela&family=Imprima&family=Julius+Sans+One&family=Kumbh+Sans:wght@600&family=Marcellus+SC&family=Mina&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:wght@700&family=Bangers&family=Blinker:wght@600&family=Julius+Sans+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Parisienne&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cantarell&display=swap');

h3 {
  position: relative;
  width: 100%;
  color: #ceffdf;
  font-family: 'Alegreya Sans SC', sans-serif;
  letter-spacing: 5px;
  // font-family: 'Julius Sans One', sans-serif;
  font-size: 48px;
  margin-top: 0;
}
p {
  text-align: justify;
  color: white;
  font-size: 18px;
  line-height: 1.5;
}
b {
  color: white;
  font-size: 26px;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    filter: brightness(0.7);
    z-index: 0;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #6fcaec;
  }
}

@media (max-width: 800px) {
  h3 {
    font-size: 30px;
  }
}