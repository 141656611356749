@import url('https://fonts.googleapis.com/css2?family=Bad+Script&display=swap');

.quotation {
    width: 100%;
    height: 45vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    .background {
        filter: brightness(0.9);
        background-color: #6fcaec;
        background-attachment: initial;
        background-position: center;
        filter: contrast(0.6) brightness(1.1);
    }
    p {
        font-family: 'Bad Script', cursive;
        font-size: 36px;
        text-align: center;
        color: white;
        z-index: 1;
    }
}

@media (max-width: 800px) {
    .quotation {
        height: 30vh;
        p {
            font-size: 26px;
        }
    }
}