.banner {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #0c4639;
    background: #3d7367;
    // background: #2c8484;
    padding: 2em 0;
    z-index: 1;
}
.column {
    flex-direction: column;
    .img-container {
        padding: 0 0 2em 0;
    }
}

@media (max-width : 1000px) {
    .banner {
        padding: 0 0 2em 0;
        flex-direction: column;
    }
}