.contact {
    background: #1f7461;
    background: #439c88;
    z-index: 1;
    padding: 2em 0;
    display: flex;
    justify-content: center;
    .contact-content {
        width: 85%;
        .bloc {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            .infos {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                min-width: 300px;
            }
        }
    }
}

@media (max-width : 1000px) {
    .contact {
        .contact-content {
            .bloc {
                align-items: center;
                flex-direction: column;
                .infos {
                    width: 100%;
                    justify-content: space-around;
                    flex-direction: row;
                }
            }
        }
    }
}