.contact-row {
    display: flex;
    align-items: center;
    padding: 0 26px;
    height: 94px;
    img {
        width: 40px;
        height: 40px;
        padding-right: 26px;
    }
    a:-webkit-any-link {
        color: white;
        &:visited {
            color: papayawhip;
        }
    }
}