.paragraph {
    width: calc(100% - 140px);
    padding: 0 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
        color: #f3f3f3;
    }
    span {
        cursor: pointer;
        text-decoration: underline;
    }
    .price-sentence {
        display: flex;
        align-items: baseline;
        justify-content: flex-end;
    }
}

@media (max-width: 1000px) {
    .paragraph {
        width: 85%;
        padding: 0;
    }
}

@media (max-width: 550px) {
    .paragraph {
        .infos {
            flex-direction: column;
        }
    }
}