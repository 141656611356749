$navbarHeight: 82px;

.navbar {
    background: #123a2cb5;
    position: fixed;
    width: 100%;
    z-index: 2;
    display: flex;
    .navbar-banner {
        position: relative;
        display: flex;
        align-items: center;
        height: $navbarHeight;
        p {
            font-size: 22px;
            font-weight: bold;
            letter-spacing: 3px;
            padding: 0 20px;
            font-family: 'Parisienne', cursive;
        }
    }
    ul {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        margin: 0;
        li {
            display: flex;
            color: white;
            padding: 0 18px;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: 170ms color ease-in-out;
            &:hover {
                color: #0da249;
            }
        }
    }
}

.navbar-column {
    background: none;
    flex-direction: column;
    .navbar-banner {
        background: #123a2c;
        width: 100%;
        z-index: 1;
        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            filter: invert(1);
            width: 90px;
            height: 100%;
            position: absolute;
            right: 0;
            cursor: pointer;
            img {
                width: 30%;
            }
        }
    }
    ul {
        padding: 0;
        z-index: 0;
        background: #123a2c;
        flex-direction: column;
        transition: .3s transform ease-in-out;
        li {
            height: 70px;
        }
    }
}

.menu-close {
    height: $navbarHeight;
    ul {
        transform: translateY(-100%);
    }
}
.menu-open {
    ul {
        transform: translateY(0);
    }
}