#header {
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    img {
        position: fixed;
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: brightness(0.7);
        z-index: -1;
    }
    h1, h2, span {
        display: flex;
        align-items: center;
        margin: 0;
    }
    h1 {
        height: 20%;
        color: white;
        font-size: 64px;
        text-align: center;
        font-family: 'Parisienne', cursive;
        letter-spacing: 8px;
    }
    h2 {
        height: 14%;
        font-family: 'Alegreya Sans SC', sans-serif;
        font-family: 'Marcellus SC', serif;
        color: white;
        font-size: 32px;
        letter-spacing: 4px;
    }
    span {
        width: 90%;
        height: 30%;
        display: flex;
        justify-content: center;
        color: white;
        font-size: 32px;
        text-align: center;
        line-height: 1.5;
        letter-spacing: 1px;
        font-family: 'Cantarell', sans-serif;
    }
}

@media (max-width: 800px) {
    #header {
        height: 90vh;
        h1, h2, span {
            margin: 0 20px;
        }
        h1 {
            font-size: 40px;
            height: 30%;
        }
        h2 {
            font-size: 22px;
        }
        span {
            font-size: 22px;
        }
    }
}