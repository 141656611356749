.card {
    width: 80%;
    min-height: 56vh;
    display: flex;
    align-items: center;
    margin: 2em auto;
    padding: 2em 0;
    background: rgba(62, 113, 79, 0.74);
    box-shadow: 1px 1px 3px 2px #212121c9;
    z-index: 1;
}
.reverse {
    flex-direction: row-reverse;
}

@media (max-width : 1000px) {
    .card {
        flex-direction: column;
        padding: 0 0 2em 0;
    }
}

@media (max-width: 800px) {
    .card {
        width: 90%;
    }
}