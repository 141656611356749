.img-container {
    width: 100%;
    height: 56vh;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 80%;
        height: 85%;
        object-fit: cover;
    }
    &.qui-je-suis {
        img {
            width: auto;
            height: 100%;
        }
    }
}

@media (max-width : 1000px) {
    .img-container {
        height: auto;
        img {
            width: 100%;
            height: 40vh;
            padding: 0 0 2em 0;
        }
        &.qui-je-suis {
            img {
                height: 40vh;
            }
        }
    }
}