.success-pop-up {
  width: fit-content;
  height: 40px;
  border-radius: 3px;
  background: #1b7c55;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateX(100%);
  transition: 0.6s transform ease-in;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  .icon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    img {
      width: 80%;
    }
  }
  p {
    margin: 0;
    font-size: initial;
    padding: 0 10px;
  }
  &.display {
    transform: translateX(0);
  }
}

@media (max-width: 550px) {
  .success-pop-up {
    height: 52px;
  }
}